<script lang="ts" setup>
import type { HomeBanners } from "@/types";

const { t } = useT();
const { open } = useTaoModals();
const { seasonInitData } = useSeasonsData({ immediate: false });

defineProps<{ banner: HomeBanners[number] }>();

const openSeasonModal = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: seasonInitData.value?.isActive ? "season_popup" : "get_ticket",
		location: "season_banner"
	});

	if (seasonInitData.value?.isActive) {
		open("LazyOModalSeasonTower");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="openSeasonModal"
	>
		<template #default>
			<AText class="banner-title" type="base h7-md" :modifiers="['uppercase', 'italic', 'medium']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText class="banner-title" type="h4 h2-md">
				<p v-html="banner.smallTitle" />
			</AText>
		</template>

		<template #actions>
			<AButton v-if="!seasonInitData?.isActive" variant="primary" size="md" class="app-banner__btn">
				{{ t("Get a bonus ticket") }}
				<ASvg name="24/ticket-lunar" class="icon-ticket" />
			</AButton>
			<AButton v-else variant="primary" size="md" class="app-banner__btn d-flex align-items-center gap-4">
				{{ t("Explore challenges") }}
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.icon-ticket {
	font-size: 24px;
}
</style>
